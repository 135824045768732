<template>
  <div class="row justify-content-center h-100">
    <!-- loader -->
    <div id="loader" style="display: none">
      <!--      <img src="assets/img/loading-icon.png" alt="icon" class="loading-icon">-->
    </div>
    <!-- * loader -->

    <!-- App Header -->
    <div class="appHeader no-border transparent position-absolute">
      <div class="left">
        <a href="#" class="headerButton goBack">
          <!--          <ion-icon name="chevron-back-outline"></ion-icon>-->
        </a>
      </div>
      <div class="pageTitle"></div>
      <div class="right">
      </div>
    </div>
    <!-- * App Header -->

    <!-- App Capsule -->
    <div id="appCapsule" class="col-sm-6 col-lg-4 col-xl-4">

      <div class="section mt-2 text-center">
        <img src="../assets/img/travancore-logo.png" width="180px"/>
      </div>
      <div class="section mb-5 p-2">

        <form action="index.html" @submit.prevent="registerMember" ref="form">
          <div class="card">
            <div class="card-body pb-1">
              <template v-for="(data) of memberData">
                <div class="form-group basic" :key="data.id" v-if="data.name !== 'Eligible Sum Insured' 
                && data.name !== 'Total Premium' && data.name !== 'Family Sum Insured' && data.name !=='Employee Contribution'
                && data.name !== 'Loading Amount' && data.name !== 'Submitted On' && data.name !== 'Relationship' && data.name !== 'Age'
                && data.name !== 'Age Band of Ex-employee' && data.name !== 'For other than kerala Circle'
                && data.name !== 'BA / SSA / District for other than Kerala Circle' && data.name !== 'Total number of members to be covered (including self)'
                && data.name !=='Mode of Payment' && data.name !=='Proof of Payment' && data.name !=='Payment Date' && data.name !=='Premium Payable'">
                  <div class="input-wrapper">
                    <label class="label" for="name">{{ data.name === 'First Name' ? 'Full Name' : data.name }} {{ data.name === 'Employee ID' ? `(As in ID card) ` : '' }}<span class="mandatory" v-if="data.id !== 12 && data.id !== 63 && data.id !== 64 && data.id !== 65 && data.id !== 66">*</span></label>                  
                    <!-- <b-datepicker v-model="form[data.id]" :show-decade-nav="true" v-if="data.name === 'Date of Birth'" :max="max"></b-datepicker> -->
                     <date-picker
            v-model="form[data.id]"
            :config="config"
            required
            :placeholder="data.name"
            v-if="data.name === 'Date of Birth'"></date-picker>

                    <select class="form-control" v-model="form[data.id]" v-else-if="data.name === 'Age Band of Ex-employee'">
                      <option value="test" class="">61-65</option>
                      <option value="66-70">66-70</option>
                      <option value="71-75">71-75</option>
                      <option value="76-80">76-80</option>
                      <option value="81-85">81-85</option>
                      <option value="86-90">86-90</option>
                      <option value="91-95">91-95</option>
                      <option value="96-100">96-100</option>
                    </select>
                    <b-form-radio-group v-model="form[data.id]" class="form-check d-inline d-flex mt-1" v-else-if="data.name === 'Gender'">
                      <b-form-radio value="Male" class="mr-2">Male</b-form-radio>
                      <b-form-radio value="Female">Female</b-form-radio>
                    </b-form-radio-group>
                    <input @input="preventMalayalam($event, data)" v-model="form[data.id]" type="text" class="form-control" id="name" :placeholder="data.name === 'First Name' ? 'Full Name' : data.name" v-else :readonly="data.name ==='Mobile' ? true : false">
                    <i class="clear-input">
                      <!--                    <ion-icon name="close-circle"></ion-icon>-->
                    </i>
                    <template v-for="error of v$.form.$errors" >
                      <div class="input-errors small text-danger justify-content-start d-flex" :key="error.$uid" v-if="data.id == error.$property">
                        <div class="error-msg">{{ error.$message }}</div>
                      </div>
                    </template>
                  </div>
                </div>
              </template>

              <div class="">
                <div class="declaration-wrapper">                  
                  <input v-model="declaration" type="checkbox" class="" id="declaration">
                  <label class="label" for="declaration">I hereby declare that the above information is true to the best of my knowledge</label>          
                  <!-- <div class="input-errors small text-danger justify-content-start d-flex" v-for="error of v$.state.$errors" :key="error.$uid">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>     -->
                </div>
              </div>            

              <div class="transparent mb-2 mt-2">
                <button type="submit" class="btn btn-primary btn-block btn-lg" :disabled="!declaration ? true : false"><b-spinner v-if="isWaitingResponse"></b-spinner><span v-else>Submit</span></button>
              </div>
            </div>
          </div>


        </form>
      </div>

    </div>
    <!-- * App Capsule -->
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { mapState } from "vuex";
import datePicker from "vue-bootstrap-datetimepicker";
import useNotyf from "../composable/useNotify";
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
export default {
  name: 'MembershipRegistration',
  data() {
    return {      
      declaration: null,
      isWaitingResponse: false,
      seconds: null,
      interval: null,
      max: this.moment().subtract(50, 'years').format('YYYY-MM-DD'),
      config: {
          format: 'DD-MM-YYYY',
          minDate: this.moment().subtract(80, 'years'),
          maxDate: this.moment(),
          date: this.moment(),
      },
      form: {
        19: process.env.VUE_APP_ELIGIBLE_SUM_INSURED,
        10: process.env.VUE_APP_FAMILY_SUM_INSURED,
        18: process.env.VUE_APP_TOTAL_PREMIUM,
        17: process.env.VUE_APP_EMPLOYEE_CONTRIBUTION,
        33: null,
        6: 'Self'
      }
    }
  },
  validations () {
    return {
      form: this.validationRule
    }
  },
  created() {
    this.phone = this.$route.query.phone?? ''    
    this.form[this.getMobileKeyID] = this.$route.query.phone
  },
  mounted() {
    this.interval = setInterval(() => {
      //console.log(this.moment().format('DD-MM-YYYY h:mm:ss a'))
      this.seconds = this.moment().format('DD-MM-YYYY h:mm:ss a')
    },1000)
    
  },
  components: {
    datePicker
  },
  computed: {
    ...mapState(['memberData']),
    formDatas() {
      return this.memberData.map(item => item.id)
    },
    validationRule() {
      let rule = {}
      this.memberData.map(item => {
        if(item.id === 1 || item.id === 3 || item.id === 4 || item.id === 8 || item.id === 39 || 
        item.id === 43 || item.id === 13 || item.id === 32 || item.id === 44) {
          rule[item.id] = {
            required
          }
        } else if(item.id === 12) {
          rule[item.id] = {
            email
          }
        }
        
      })
      return rule
    },
    formatLabel() {
      let label = []
      this.memberData.map(item => {
        switch(item.name) {
          case 'First Name':
            label.push('Name of BSNL Ex-employee')
            break;
          case "Employee ID":
            label.push('HR Number')
            break;
          case "Date of Birth":
            label.push('Date Of Birth of Ex-employee')
            break;
          case "Age":
            label.push('Age of Ex-employee')
            break;
          case "Gender":
            label.push('Gender of Ex-employee')
            break;
          case "Mobile":
            label.push('Contact Mobile Number')
            break;
          default: 
            label.push(item.name)
        }   
      })
      return label;
    },
    getMobileKeyID() {
      return this.memberData.find(item => item.name === 'Mobile').id
    }
  },
  methods: {
    preventMalayalam(event, data) {
      // Regex to detect Malayalam characters
      const malayalamRegex = /[\u0D00-\u0D7F]/g;

      // Get the input value
      const inputValue = event.target.value;

      // Remove Malayalam characters
      const cleanedValue = inputValue.replace(malayalamRegex, '');

      // Update the input value if it contained any Malayalam characters
      if (inputValue !== cleanedValue) {
        event.target.value = cleanedValue;
        this.$set(this.form, data.id, cleanedValue); // Ensure v-model updates
      }
    },
    registerMember() {
      this.form[33] = this.seconds
      let form = {
        'employee_prospect_id': process.env.VUE_APP_EMPLOYEE_PROSPECT_ID,
        'enrollment_validity': process.env.VUE_APP_ENROLLMENT_VALIDITY,
        'confirmation_email_template_id': process.env.VUE_APP_CONFIRMATION_EMAIL_TEMPLATE_ID,
        'employee_id': this.form[1],
        'member_details': this.form,
      }
      this.v$.$validate()
      if(this.v$.$error) return
      this.isWaitingResponse = true
      delete this.form[33];
      this.$axios.post('create-employee', form).then(({data}) => {
        this.isWaitingResponse = false
        if(data.status === 1) {
          localStorage.setItem('LoggedUser', false)
          window.location.href = data.data.enrollment_url
        } else if(data.status === 0) {
          useNotyf().error(data.message)
        }
      }).catch(() => {
        this.isWaitingResponse = false
      })
    }
  },
  beforeDestroy() {
    // prevent memory leak
    clearInterval(this.interval)
  },
  setup: () => {
    return { v$: useVuelidate() }
  }
}
</script>

<style scoped>
>>>.b-form-btn-label-control.form-control > .form-control {
  padding-top: 7px!important;
}
.mr-2 {
  margin-right: 20px;
}
>>>input[type="radio"] {
  margin-right: 5px !important;
}
.declaration-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.declaration-wrapper label {
    text-align: left;
    margin-left: 10px;
}
.mandatory {
  color: red;
  font-size: 16px;
}

</style>
